var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('MyHeader',{attrs:{"title":_vm.headtitle,"ShowBack":true}}),_c('van-cell-group',[_vm._l((_vm.lstData),function(mData){return _c('van-cell',{key:mData.id,attrs:{"title":mData.eventName,"icon":mData.isNormal ? 'checked' : 'warning'},on:{"click":function($event){return _vm.onShowDetail(
          mData.type,
          mData.isNormal,
          mData.repairId,
          mData.type == 3 || mData.type == 4 || mData.type == 5
            ? mData.logicName
            : ''
        )}},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('van-tag',{staticClass:"custtag",attrs:{"plain":mData.isNormal,"type":mData.isNormal ? 'success' : 'danger',"color":((mData.type == 3 || mData.type == 4 || mData.type == 5) && mData.logicName == '' ? '#C0C0C0' : ''),"size":"medium"}},[_vm._v(_vm._s(mData.type == 0 ? (mData.numValue + mData.unitName + "(" + mData.minValue + "-" + mData.maxValue + ")") : ( (mData.type == 3 || mData.type == 4 || mData.type == 5) ? (mData.logicName == "" ? _vm.$t("lang.hint_noattachment") : _vm.$t("lang.preview")) : mData.logicName )))])]},proxy:true}],null,true)})}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHint),expression:"showHint"}],staticStyle:{"margin":"20px","text-align":"center","display":"block","color":"#969799"}},[_vm._v(" "+_vm._s(_vm.$t("lang.nodata"))+" ")])],2),_c('van-dialog',{attrs:{"show-cancel-button":"","show-confirm-button":false,"cancel-button-text":_vm.$t('lang.close')},on:{"close":function($event){return _vm.onClose()}},model:{value:(_vm.showVideo),callback:function ($$v) {_vm.showVideo=$$v},expression:"showVideo"}},[_c('video-player',{ref:"videoPlayer",staticClass:"video-player-box vjs-big-play-centered",attrs:{"options":_vm.playerOptions,"playsinline":true,"customEventName":"customstatechangedeventname"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }