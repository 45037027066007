<template>
  <div>
    <MyHeader :title="headtitle" :ShowBack="true" />
    <van-cell-group>
      <van-cell v-for="mData in lstData" :key="mData.id" :title="mData.eventName"
        :icon="mData.isNormal ? 'checked' : 'warning'" @click="onShowDetail(
            mData.type,
            mData.isNormal,
            mData.repairId,
            mData.type == 3 || mData.type == 4 || mData.type == 5
              ? mData.logicName
              : ''
          )
          ">
        <template #right-icon>
          <van-tag :plain="mData.isNormal" :type="mData.isNormal ? 'success' : 'danger'"
            :color="((mData.type == 3 || mData.type == 4 || mData.type == 5) && mData.logicName == '' ? '#C0C0C0' : '')"
            size="medium" class="custtag">{{
              mData.type == 0
              ? (mData.numValue + mData.unitName + "(" + mData.minValue + "-" + mData.maxValue + ")")
              : (
                (mData.type == 3 || mData.type == 4 || mData.type == 5)
                ? (mData.logicName == "" ? $t("lang.hint_noattachment") : $t("lang.preview"))
                : mData.logicName
              )
            }}</van-tag>
        </template>
      </van-cell>
      <div v-show="showHint" style="margin: 20px; text-align: center; display: block; color: #969799">
        {{ $t("lang.nodata") }}
      </div>
    </van-cell-group>

    <van-dialog v-model="showVideo" show-cancel-button :show-confirm-button="false" :cancel-button-text="$t('lang.close')"
      @close="onClose()">
      <video-player class="video-player-box vjs-big-play-centered" ref="videoPlayer" :options="playerOptions"
        :playsinline="true" customEventName="customstatechangedeventname">
      </video-player>
    </van-dialog>
  </div>
</template>
<style scoped>
.van-icon-checked {
  color: green;
}

.van-icon-warning {
  color: red;
}

.custtag {
  height: 24px;
}
</style>
<script>
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import { ImagePreview } from "vant";
import MyHeader from "@/components/Header.vue";
import { GetPollingQueryDetail, GetServerUrl } from "@/api/index.js";
export default {
  components: { MyHeader, videoPlayer },
  data() {
    return {
      lstData: [],
      headtitle: "",
      showHint: true,
      ServerUrl: "",

      showVideo: false,
      playerOptions: {
        muted: false,
        language: "zh-CN",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        fluid: true,
        sources: [
          {
            type: "video/mp4",
            src: "",
          },
        ],
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
    };
  },

  activated() {
    if (this.$route.params.id) {
      let id = this.$route.params.id;
      this.headtitle = this.$route.params.title;
      if (id) this.Query(id);
    }
  },

  methods: {
    Query(id) {
      let that = this;
      GetPollingQueryDetail(
        {
          token: this.$store.getters.getToken,
          patrolRecordId: id,
        },
        (ret) => {
          if (ret) {
            if (ret.code == 0) {
              this.lstData = ret.data;
              this.showHint = that.lstData.length == 0;
            } else this.$Toast(ret.message);
          } else this.$Toast(this.$t("lang.queryfail"));
        }
      );
    },

    onClose() {
      this.$refs.videoPlayer.player.pause();
    },

    onShowDetail(eventtype, isNormal, repairId, eventvalue) {
      if (eventtype == 3) {
        //图片
        if (eventvalue == "") {
          this.$Toast(this.$t("lang.hint_noattachment"));
        } else {
          this.ServerUrl = GetServerUrl();
          let imgurl = eventvalue.replace(
            RegExp("/app/images", "g"),
            this.ServerUrl + "app/images"
          );
          let mImage1 = imgurl.replace(RegExp("preview/", "g"), "").split("|");
          ImagePreview({
            images: mImage1,
            startPosition: 0,
          });
        }
      } else if (eventtype == 4 || eventtype == 5) {
        //录音,视频
        if (eventvalue == "") {
          this.$Toast(this.$t("lang.hint_noattachment"));
        } else {
          this.ServerUrl = GetServerUrl();
          this.playerOptions.sources[0].src = this.ServerUrl + eventvalue;
          this.showVideo = true;
        }
      } else {
        if (isNormal) return;
        if (repairId == 0) {
          this.$Toast(this.$t("lang.hint_noattachment"));
          return;
        }
        this.$router.push({
          name: "QueryDetail",
          query: {
            id: repairId,
          },
        });
      }
    },
  },
};
</script>
